// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-writing-js": () => import("/opt/build/repo/src/templates/writing.js" /* webpackChunkName: "component---src-templates-writing-js" */),
  "component---src-templates-script-js": () => import("/opt/build/repo/src/templates/script.js" /* webpackChunkName: "component---src-templates-script-js" */),
  "component---src-pages-comedy-js": () => import("/opt/build/repo/src/pages/comedy.js" /* webpackChunkName: "component---src-pages-comedy-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-files-js": () => import("/opt/build/repo/src/pages/my-files.js" /* webpackChunkName: "component---src-pages-my-files-js" */),
  "component---src-pages-newsletter-js": () => import("/opt/build/repo/src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-privacy-policy-js": () => import("/opt/build/repo/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-writing-js": () => import("/opt/build/repo/src/pages/writing.js" /* webpackChunkName: "component---src-pages-writing-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

